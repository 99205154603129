<template>
	<Section :options="computedOptions.sectionOptions" class="apartment">

		<p class="back-to-apartments">
			<router-link :to="{ name : computedOptions.apartmentsPageName, params : { lang : currentLanguageCode }}">
				<i class="icon-angle-left1"></i>
				{{ $t("hospitality.back-to-apartments") }}
			</router-link>
		</p>

		<div class="single-post mb-0" v-if="apartment">

			<!-- Single Post
			============================================= -->
			<div class="entry clearfix">

				<!-- Entry Title
				============================================= -->
				<div class="entry-title">
					<h1>{{ getLocalizedText(apartment.title) }}</h1>

					<LocalizedContent :localizedContent="apartment.summary" class="summary bottommargin" tag="div" ></LocalizedContent>
				</div><!-- .entry-title end -->

				<!-- Entry Meta
				============================================= -->
				<div v-if="false" class="entry-meta">
					<ul>
						<!-- <li><i class="icon-calendar3"></i> {{ formatDate(apartment.date) }}</li> 
						<li><a href="#"><i class="icon-user"></i> admin</a></li>
						<li><i class="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
						<li><a href="#"><i class="icon-comments"></i> 43 Comments</a></li>
						<li><a href="#"><i class="icon-camera-retro"></i></a></li> -->
					</ul><!-- .entry-meta end -->
				</div>

				<!-- Entry Image
				============================================= -->
				<div v-if="apartment.pictures && apartment.pictures.length > 0" class="entry-image bottommargin">
					<Swiper
						v-if="showSwipper"
						:options="getSwiperOptions()"
						:components="getSwiperComponents()"
					/>
				</div><!-- .entry-image end -->

				<!-- Entry Content
				============================================= -->
				<div class="entry-content mt-0">
					<LocalizedContent :localizedContent="apartment.description" class="description bottommargin" tag="div" ></LocalizedContent>
					<!-- Post Single - Content End -->

					<!-- Tag Cloud
					============================================= -->
					<!--
					<div class="tagcloud clearfix bottommargin">
						<a href="#">general</a>
						<a href="#">information</a>
						<a href="#">media</a>
						<a href="#">press</a>
						<a href="#">gallery</a>
						<a href="#">illustration</a>
					</div>-->
					<!-- .tagcloud end -->
					<AvailabilityCalendar :apartment="apartment"></AvailabilityCalendar>

					<ContactDetails :options="getContactDetailsOptions()" :labels="getContactDetailsLabels()" />

					<!-- Post Single - Share
					============================================= -->
					<!--
					<div class="si-share d-flex align-items-center topmargin">
						<span>{{ $t("apartments.post.share") }}</span>
						<ShareLinks :title="getLocalizedText(apartment.title)" ></ShareLinks>
					</div>
					-->
					<!-- Post Single - Share End -->

				</div>
			</div><!-- .entry end -->
		
		</div>
		<p v-else>
			{{ $t("hospitality.error.not-found") }}
		</p>

		<p class="back-to-apartments">
			<router-link :to="{ name : computedOptions.apartmentsPageName, params : { lang : currentLanguageCode }}">
				<i class="icon-angle-left1"></i>
				{{ $t("hospitality.back-to-apartments") }}
			</router-link>
		</p>
	</Section>
</template>

<style>

/* We add top margin between the content and the back to news link */
.apartment.container > div {
	margin-top:20px;
}

.apartment .swiper .apartment-picture {
	height: 400px;
}
.apartment .swiper .apartment-picture img {
	height: 400px;
}

.apartment .entry-title h1 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.apartment .entry-image img {
	max-height:600px;
	width:auto;
}

.apartment .entry-content .description .media {
	display:block;
	max-width:800px;
}

.apartment .entry-content .description p {
	margin-bottom: 15px;
}

.apartment .entry-content .description ul {
	margin-left: 20px;
}

.apartment .entry-content .contact-details .details {
	padding-left: 0px;
}

/* We add some margin between share title and icons */
.apartment .si-share span {
    margin-right: 10px;
}

.apartment .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}

</style>

<script lang="ts">
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import ShareLinks from '@root/src/client/components/panels/input/ShareLinks.vue';
import AvailabilityCalendar from '@root/src/client/components/panels/hospitality/AvailabilityCalendar.vue';
import Swiper from '@root/src/client/components/cms/canvas/slider/Swiper.vue';
import { formatDay as fwkFormatDay, FormatDateType, formatMonth as fwkFormatMonth } from '@igotweb-node-api-utils/formatter';
import ContactDetails,  { ContactDetailsOptions, ContactDetailsLabels } from '../ContactDetails.vue';

import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, Ref, ref } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

/** @cmsOptions */
export interface ApartmentDetailsOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
    /** @cmsType CmsText */
    apartmentCode?:CmsText;
    /** @cmsType CmsText */
    apartmentCodeParam?:CmsText;
    /** @cmsType CmsText */
    apartmentsPageName?:CmsText;
}

/** @cmsLabels */
export interface ApartmentDetailsLabels {
    /** @cmsType CmsLabel */
    pictureAlt?:CmsLabel
    /** @cmsType CmsLabel */
    title?:CmsLabel
    /** @cmsType CmsLabel */
    subtitle?:CmsLabel
    /** @cmsType CmsContent */
    description?:CmsContent
    /** @cmsType CmsLabel */
    download?:CmsLabel
}

/** @cmsSlots */
export interface ApartmentDetailsSlots {
    
}


export default defineComponent({
    props: {
        options: Object as PropType<ApartmentDetailsOptions>,
        labels: {
          type: Object as PropType<ApartmentDetailsLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ApartmentDetailsSlots>
    },
    components : {
        Section,
        LocalizedContent,
		ShareLinks,
		Swiper,
		ContactDetails,
		AvailabilityCalendar
    },
    setup(props, context) {

        const app = getApp();
        const router = useRouter();
        const store = useStore();
        const cms = useCmsModule();

        const computedOptions:ApartmentDetailsOptions = {
            sectionOptions : {
                section:false
            },
            apartmentCodeParam:"apartmentCode",
			apartmentsPageName:"hospitality-apartments",
            ...props.options
        };

        const computedLabels:ApartmentDetailsLabels = {
            download : {
                fr : "Télécharger"
            },
            ...props.labels
        }

		const apartment = computed(() => {
			// We get the post path
			var apartmentCode:string|null = null;
			if(computedOptions.apartmentCode && computedOptions.apartmentCode != "") {
				apartmentCode = computedOptions.apartmentCode
			}
			else if(store.state.route && store.state.route.params && store.state.route.params[computedOptions.apartmentCodeParam!]) {
				apartmentCode = store.state.route.params[computedOptions.apartmentCodeParam!];
			}

			// We retrieve the post
			if(apartmentCode) {
				return app.$hospitality_apartment(apartmentCode);
			}
		})

		
		const getSwiperOptions = () => {
			return {
				sectionOptions : {
					section:false,
					container:false
				}
			}
		}

		const getSwiperComponents = () => {
			return {
				"slides" : apartment.value.pictures.map((picturePath:any) => {
					var pictureURL = app.getStaticURLFromPath(picturePath);
					return {
						"path": "canvas/slider/SwiperSlide",
						"props": {
							"class": "apartment-picture",
							"options": {
								"height":"none",
								"flex" : true,
								"pictureURL" : pictureURL
							}
						}
					}
				})
			}
		}

		const showSwipper:Ref<boolean> = ref(false);
		const loadComponents = () => {
			cms.components.loadComponent("canvas/slider/SwiperSlide").then(() => {
				showSwipper.value = true;
			})
		}
		loadComponents();

		/*
		onBeforeMount(() => {
			addComponentsToBeMounted();
		})

		onBeforeUpdate(() => {
			addComponentsToBeMounted();
		})

		const addComponentsToBeMounted = () => {
			var componentsToBeMounted:any[] = [];
			componentsToBeMounted.push({
				"path": "canvas/slider/SwiperSlide"
			})
			cms.components.addComponentToBeMounted(componentsToBeMounted)
		}
		*/

		const getContactDetailsOptions = ():ContactDetailsOptions => {
			return {
				sectionOptions : {
					section:false
				},
				address:apartment.value.address
			}
		}

		const getContactDetailsLabels = ():ContactDetailsLabels => {
			return {
				title: {
					fr: "Adresse",
					en: "Address"
				},
				mapMakerLabel: apartment.value.title
			}
		}

		const currentLanguageCode = computed(() => {
			return store.state.languages.currentLanguageCode;
		})

		const formatMonth = (date:Date) => {
			return fwkFormatMonth(date, currentLanguageCode.value, {type:FormatDateType.LONG});
		}

		const formatDate = (date:Date) => {
			return fwkFormatDay(date, currentLanguageCode.value)
		}

        return {
			getContactDetailsOptions,
			getContactDetailsLabels,
			showSwipper,
			getSwiperOptions,
			getSwiperComponents,
			currentLanguageCode,
            formatMonth,
			formatDate,
			apartment,
            computedOptions: {
                ...computedOptions
            },
            labels:computedLabels
        }

    }
})
</script>