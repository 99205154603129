

<template>
     <!-- Slider
    ============================================= -->
    <Section id="slider" :options="computedOptions.sectionOptions" :class="sliderCssClass" ref="slider">
        <div v-swiper="swiperOption" class="swiper slider-inner">

            <div class="swiper-wrapper">
                <CmsComponent v-for="(component, index) in components.slides" :key="index+'_slides'" :component="component"></CmsComponent>
            </div>
            <div v-if="components.slides.length > 1" class="slider-arrow-left"><i class="icon-angle-left"></i></div>
            <div v-if="components.slides.length > 1" class="slider-arrow-right"><i class="icon-angle-right"></i></div>
        </div>
    </Section><!-- #slider end -->
    
</template>

<style>

.slider-swiper .swiper {
    display:flex;
    justify-content: center;
}

.slider-swiper .swiper .swiper-wrapper {
    flex-basis:content;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import type { Ref } from '@fwk-node-modules/vue';
import { Component } from '@fwk-client/modules/cms/helpers/components';
import { CmsLabel, CmsBoolean, CmsText, CmsPicture, CmsList, CmsNumber } from '@fwk-client/modules/cms/types';
import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';

// Import Swiper Vue.js components
import { SwiperOptions as ComponentSwiperOptions } from 'swiper';

/*
slides = [
        {
            thumb: "images/slider/boxed/thumbs/2.jpg",
            link: undefined,
            image: "images/slider/boxed/2.jpg",
            labelPosition: " justify-content-start align-items-end",
            labelStyle: " bg-light text-dark ms-3 mb-3",
            altImageLabel: {
                "fr" : "Label de l'image"
            },
            label: {
                "fr" : "Le label du slide"
            }
        }
    ]
*/

/** @cmsOptions */
export interface SwiperOptions {
     /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions;
    /** @cmsType CmsBoolean */
    includeHeader?:CmsBoolean // if true, the slider is included in the header which is transparent.
    /** @cmsType CmsNumber */
    height?:CmsNumber // The percentage of height of the screen.
    /** @cmsType CmsNumber */
    autoplay?:CmsNumber;
    /** @cmsType CmsBoolean */
    centeredSlides?:CmsBoolean;
    /** @cmsType CmsBoolean */
    arrows?:CmsBoolean;
    /** @cmsType CmsNumber */
    speed?:CmsNumber;
    /** @cmsType CmsNumber */
    pause?:CmsNumber;
}

/** @cmsSlots */
export interface SwiperSlots {
    slides?:Component[]
}

export default defineComponent({
    props: {
        options: Object as PropType<SwiperOptions>,
        components: Object as PropType<SwiperSlots>
    },
    components: {
        Section
    },
    setup(props, context) {
        const slider:Ref<any|null> = ref(null);

        const computedOptions:SwiperOptions = {
            centeredSlides:false,
            arrows:true,
            speed:500,
            pause:7000,
            ...props.options,
            sectionOptions: {
                section:false,
                container:false,
                noMargin:false,
                ...(props.options ? props.options.sectionOptions : {})
            }
        };

        const slides = (props.components && props.components.slides) ? props.components.slides.map((slide:any) => {
            if(computedOptions.height) {
                slide.props.height = computedOptions.height;
            }
            return slide;
        }) : [];

        const sliderCssClass = computed(() => {
            var cssClass = "slider-swiper slider-element dark";
            
            if(computedOptions.height != undefined && typeof computedOptions.height == "string" && computedOptions.height == "auto") {
                cssClass += " h-auto";
            }
            else if(computedOptions.height != undefined && computedOptions.height > 0) {
                cssClass += " min-vh-"+computedOptions.height;
            }
            if(computedOptions.includeHeader) {
                cssClass += " include-header"
            }
            return cssClass;
        })

        const swiperOption:ComponentSwiperOptions = {
            navigation: computedOptions.arrows ? {
                nextEl: '.slider-arrow-right',
                prevEl: '.slider-arrow-left',
            } : undefined,
            slidesPerView : 'auto',
            spaceBetween : 50,
            simulateTouch:slides.length > 1,
            setWrapperSize:false,
            centeredSlides:computedOptions.centeredSlides,
            speed: computedOptions.speed,
            autoplay: computedOptions.autoplay ? {
                delay: computedOptions.autoplay
            } : undefined
        }

        onMounted(() => {
            animateElements(slider.value.$el as HTMLElement)
        })

        onBeforeUnmount(() => {
            removeElementsAnimation(slider.value.$el as HTMLElement)
        })

        return {
            slider,
            swiperOption,
            sliderCssClass,
            computedOptions: {
                ...computedOptions
            },
            slides
        }

    }
})
</script>