export interface Price {
    amount:number,
    nonDiscountedAmount?:number
}

/**
 * roundAmount
 * This method ensure that any amount provided from browser as input is rounded with 2 decimals.
 * @param amount - the amount sent as input
 */
export function roundAmount(amount:number|string) {
    return Math.round((parseFloat(amount as string) + Number.EPSILON) * 100) / 100
}

/**
 * addPrice
 * This method add price to an exisitng one
 * @param price 
 * @param priceToAdd 
 * @param quantity 
 */
export function addPrice(price:Price, priceToAdd:Price, quantity?:number) {
    if(quantity == undefined) {
        quantity = 1;
    }
    price.amount += roundAmount(priceToAdd.amount * quantity);
    if(priceToAdd.nonDiscountedAmount != undefined) {
        if(price.nonDiscountedAmount == undefined) {
            price.nonDiscountedAmount = price.amount;
        }
        price.nonDiscountedAmount += roundAmount(priceToAdd.nonDiscountedAmount * quantity);
    }
}

/**
 * minusPrice
 * This method minus price to an exisitng one
 * @param price 
 * @param priceToAdd 
 * @param quantity 
 */
export function minusPrice(price:Price, priceToMinus:Price, quantity?:number) {
    if(quantity == undefined) {
        quantity = 1;
    }
    price.amount = Math.max(0, price.amount - roundAmount(priceToMinus.amount * quantity));

    if(priceToMinus.nonDiscountedAmount != undefined) {
        if(price.nonDiscountedAmount == undefined) {
            price.nonDiscountedAmount = price.amount;
        }
        price.nonDiscountedAmount = Math.max(0, price.amount - roundAmount(priceToMinus.nonDiscountedAmount * quantity));
    }
}