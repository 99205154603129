<template>
    <div class="availability-calendar" ref="availabilityCalendar">

        <h2>{{ $t("hospitality.availability-calendar.title") }}</h2>
        <p>
            {{ $t("hospitality.availability-calendar.note") }}
        </p>

        <div class="row" v-if="periodAvailabilities.length > 0">

            <div class="col-auto">

                <div v-for="(availability, availabilityIndex) in visibleAvailabilities" :key="availabilityIndex + '-availabilityPeriod'" class="period card">
                    <div class="card-body">
                        <h4 class="card-title"> {{ getLocalizedText(availability.title) }}</h4>
                        <div class="card-text">
                            {{ formatDate(availability.startDate) }} - {{ formatDate(availability.endDate) }}<br/>
                            <div v-if="availability.periodValidation">
                                <span v-if="availability.periodValidation.lastDateBeforeValidationPeriod">Date limite de réservation: {{ formatDate(availability.periodValidation.lastDateBeforeValidationPeriod) }} <br/></span>
                                <span v-if="(new Date(availability.periodValidation.expectedValidationDate)).getTime() > (new Date()).getTime()">Date de validation: {{ formatDate(availability.periodValidation.expectedValidationDate) }}</span>
                                
                            </div>
                            Prix de la nuit: {{ formatPriceAmount(availability.ratings[0].price) }}
                        </div>
                    </div>
                </div>


            </div>

        </div>

        <div class="row">

            <div class="col-md-9">
                <FullCalendar ref="fullCalendar" :options="calendarOptions"></FullCalendar>
            </div>

            <div class="col-md-3">
                <div class="selection">
                    <h3 class="font-body fw-normal ls1">{{ $t("hospitality.selection.title") }}</h3>
                    <ul>
                        <li v-if="selectionForm.startDate">{{ $t("hospitality.selection.check-in") }} <b> {{ formatDate(selectionForm.startDate) }}</b></li>
                        <li v-else>{{ $t("hospitality.selection.check-in") }} <b> {{ $t("hospitality.selection.select-a-date") }} </b></li>
                        <li v-if="selectionForm.endDate"> {{ $t("hospitality.selection.check-out") }} <b> {{ formatDate(selectionForm.endDate) }}</b></li>
                        <li v-else>{{ $t("hospitality.selection.check-out") }} <b> {{ $t("hospitality.selection.select-a-date") }} </b></li>
                        <li v-if="computedRate">{{ $t("hospitality.selection.number-of-nights") }} <b>  {{ computedRate.numberOfNights }} </b></li>
                        <li v-if="computedRate">{{ $t("hospitality.selection.amount") }} <b> {{ formatPriceAmount(computedRate.price) }} </b></li>
                    </ul>
                    <button :disabled="!computedRate" class="button button-3d ladda-button m-0 book" data-style="zoom-in" type="button" @click="onAddToCartClick">
                        {{ $t("hospitality.selection.book-button") }}
                    </button>
                    <div class="note">{{ $t("hospitality.selection.book-note") }}</div>
                </div>
            </div>

        </div>
        
        
    </div>

</template>

<style>

:root {
    --fc-today-bg-color: rgba(243, 209, 209, 0.25);
    --fc-highlight-color: rgba(55, 255, 0, 0.522);
    /* --fc-highlight-color: var(--secondary-color) */
}

.fc .fc-multimonth {
    border: 0px;
}

.availability-calendar .selection ul {
    margin-left:40px;
}

.availability-calendar button.book {
    width: 100%
}

.availability-calendar div.note {
    text-align:center;
}

.availability-calendar .selection {
    margin: 0 0 40px 0;
    padding: 5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.availability-calendar .fc-day.selected .fc-daygrid-day-number {
    color: #FFFFFF
}

/* We ensure days from other months are still visible */
.availability-calendar .fc-day.selected .fc-daygrid-day-top {
    opacity: 1;
}

/* .availability-calendar .fc-day.selected .fc-daygrid-bg-harness, */
.availability-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-event {
    background: var(--secondary-color) !important; 
    opacity: 1;
}
.availability-calendar .fc-day.selected .fc-daygrid-bg-harness .fc-highlight {
    background: transparent !important; 
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onBeforeMount, onBeforeUpdate, onMounted, Ref, ref, watch, reactive } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { AvailabilitiesOptions } from '@fwk-client/modules/hospitality/helpers/apartment';
import { Calendar } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'; 
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import deLocale from '@fullcalendar/core/locales/de';
import { formatDay, FormatDateType, FromLocalToUTC, formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { addPrice } from '@igotweb-node-api-utils/price';
import { startMonth, endMonth, addDays, removeDays, startDay, endDay } from '@igotweb-node-api-utils/date';

import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';


export default defineComponent({
    props: {
        apartment: Object as PropType<any>,
    },
    components : {
        FullCalendar
    },
    setup(props, context) {

        const app = getApp();
        const router = useRouter();
        const store = useStore();
        const cms = useCmsModule();

        const isCalendarLoading:Ref<boolean> = ref(false);
        const availabilities:Ref<any> = ref(null)
        const periodAvailabilities:Ref<any[]> = ref([])
        const fullCalendar:Ref<HTMLElement|null> = ref(null);

        const availabilityCalendar:Ref<HTMLElement|null> = ref(null);

        const selectionForm:any = reactive({
            startDate:null,
            endDate:null
        })

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
            var bookButton:HTMLButtonElement|null = availabilityCalendar.value!.querySelector( 'button.ladda-button.book' );
            laddaSubmit = Ladda.create(bookButton!);
            // By default we load the calendar for the current and next three months
            updateCalendar(props.apartment.code);
        })

        const updateCalendar = async (apartmentCode:string, availOptions?:AvailabilitiesOptions) => {
            isCalendarLoading.value = true;
            var avails:any = await app.$hospitality.apartment.getAvailabilities(apartmentCode, availOptions);
            isCalendarLoading.value = false;
            availabilities.value = {
                ...availabilities.value,
                ...avails.calendar
            };
            // For each availability we check if not already in the list
            for(var periodAvailability of avails.periodAvailabilities) {
                var existingPeriodAvailability = periodAvailabilities.value.find((existingPeriodAvailability:any) => {
                    return existingPeriodAvailability._id == periodAvailability._id;
                })
                if(!existingPeriodAvailability) {
                    periodAvailabilities.value.push(periodAvailability);
                }
            }
        }

        const currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        const calendarLocale = computed(() => {
            var calendarSpecificLocales:any = {
                "en" : "en-gb"
            }
            if(calendarSpecificLocales[currentLanguageCode]) {
                return calendarSpecificLocales[currentLanguageCode]
            }
            return currentLanguageCode;
        })

        interface SectionInfo {
            start:Date
            end:Date
        }

        const onDateClick = (info:any) => {
            // Nothing to do
        }

        const computeSectionInfo = (info:any) => {
            // We check if selection is possible
            var startDate = FromLocalToUTC(info.start);
            var endDate = FromLocalToUTC(info.end);

            // We check if the current selection is a range or not.
            var isRangeSelected = true;
            if(endDate.getTime() - startDate.getTime() == 24*60*60*1000) {
                isRangeSelected = false;
            }
            else {
                removeDays(endDate,1)
            }

            var startDateToValidate = startDate;
            var endDateToValidate = endDate;

            if(!isRangeSelected && selectionForm.startDate != null) {
                // We are selecting the end date of a range.
                // So we will validate the full range
                startDateToValidate = selectionForm.startDate;
                endDateToValidate = startDate;
                
                if(startDateToValidate.getTime() > endDateToValidate.getTime()) {
                    // We are selecting end of range as a start date
                    startDateToValidate = startDate;
                    endDateToValidate = selectionForm.startDate;
                }
            }

            return {
                startDateToValidate,
                endDateToValidate,
                isRangeSelected
            }
        }

        /**
         * checkSelectAllow
         * Triggered when the user tries to select a cell or a range.
         */
        const checkSelectAllow = (info:any) => {

            const { startDateToValidate, endDateToValidate, isRangeSelected } = computeSectionInfo(info);

            // We check that all date part of the selection are possible
            var isAllowed = checkIfSelectionAllowed(startDateToValidate, endDateToValidate);

            // If we are trying to select the end date, and selection is not allowed
            // we need to reset full selection as calendar selection is reseted
            /*
            if(!isAllowed && !isRangeSelected && selectionForm.startDate != null) {
                selectionForm.startDate = null;
            }
            */
            
            return isAllowed;
        }

        const getAvailabilityFromDate = (date:Date) => {
            // We get the price associated to selection
            var monthKey:string = date.getFullYear() + '-' + (date.getMonth() + 1);
            var dayIndex = date.getDate() - 1;

            // If there is no calendar day information we cannot select
            if(typeof availabilities.value[monthKey] == 'undefined' ||
                    typeof availabilities.value[monthKey][dayIndex] == 'undefined') {
                return undefined;
            }

            return availabilities.value[monthKey][dayIndex];
        }

        /**
         * checkIfSelectionAllowed
         * startDate : checkin
         * endDate : checkout
         * This method check that there is an availability and price for all nights (excluding checkout date).
         */
        const checkIfSelectionAllowed = (startDate:Date, endDate:Date) => {

            if(startDate.getTime() == endDate.getTime()) {
                return false;
            }

            // We loop over all dates except last one as it is checkout date and not stay.
            for(var day = new Date(startDate); day.getTime() < endDate.getTime() ; addDays(day, 1) ) {
                var availability = getAvailabilityFromDate(day)
                // If there is no price associated to the day we cannot select
                if(!availability || !availability.price) {
                    return false;
                }
            }
            return true;
        }

        const selectCalendarCells = (startDate:Date, endDate?:Date) => {
            if(!endDate) {
                endDate = new Date(startDate);
            }
            // We unselect other cells
            var selectedCells = ((fullCalendar.value as any).calendar.el as HTMLElement).querySelectorAll(".fc-day.selected");
            selectedCells.forEach((cell:Element) => {
                // We check if the selected cell is not in the range to be selected
                let dateCell = cell.getAttribute("data-date");
                let dateForCell = new Date(dateCell!);
                if(dateForCell.getTime() < startDate.getTime() || dateForCell.getTime() > endDate!.getTime()) {
                    cell.classList.remove("selected");
                }
            })

            for(var dateForCell = new Date(startDate); dateForCell.getTime() <= endDate.getTime(); addDays(dateForCell, 1)) {
                var dateAttibute = formatDateForInput(dateForCell, true);
                // We may have several cells for the dates when same day is visible in two month calendars
                var dateCells = (fullCalendar.value as any).calendar.el.querySelectorAll(".fc-day[data-date='"+dateAttibute+"']");
                if(dateCells && dateCells.length > 0) {
                    for(var dateCell of dateCells) {
                        if(!dateCell.classList.contains("selected")) {
                            // We add attribute only when date is visible
                            dateCell.classList.add("selected")
                        }
                    }
                }
            }
        }

        const onDatesSelected = (info:SectionInfo) => {

            const { startDateToValidate, endDateToValidate, isRangeSelected } = computeSectionInfo(info);
            
            // We update the form
            if(isRangeSelected) {
                selectionForm.startDate = startDateToValidate;
                selectionForm.endDate = endDateToValidate;

                selectCalendarCells(startDateToValidate, endDateToValidate);
            }
            else if(selectionForm.startDate != null) {
                // We trigger the range selection to avoid blink
                let calendarApi = (fullCalendar as any).value.getApi()
                var startDateToSelect = startDateToValidate;
                var endDateToSelect = endDay(endDateToValidate);
                selectionForm.startDate = startDateToValidate;
                selectionForm.endDate = endDateToValidate;

                selectCalendarCells(selectionForm.startDate, selectionForm.endDate);
            }
            else {
                selectionForm.startDate = startDateToValidate;

                selectCalendarCells(startDateToValidate);
            }
        }

        const onDatesUnselected = (info:any) => {
            // The user is doing a new selection
            // If a full period is selected, we unselect both
            // info.jsEvent is null when navigating between months
            if(info.jsEvent && selectionForm.startDate && selectionForm.endDate ) {
                // The user starts to select a new period
                selectionForm.startDate = null;
                selectionForm.endDate = null;
            }
            else if(info.jsEvent == null) {
                // We are navigating so we need to keep what was selected
                if(selectionForm.startDate && selectionForm.endDate) {
                    // We have range selected
                    // We trigger the range selection
                    let calendarApi = (fullCalendar as any).value.getApi()
                    var endDate = new Date(selectionForm.endDate);
                    addDays(endDate,1);
                    var selection = {
                        start: new Date(selectionForm.startDate),
                        end: endDate,
                    }
                    calendarApi.select(selection)
                }
            }
        }

        const dayCellClassNames = (info:any) => {
            // console.log(info)
        }

        const numberOfMonths = 2;
        const currentStartDate:Ref<Date> = ref(startMonth(new Date()))
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + numberOfMonths);
        const currentEndDate:Ref<Date> = ref(endMonth(endDate))

        const calendarOptions:Ref<any> = ref({
            plugins: [interactionPlugin, multiMonthPlugin],
            locales: [frLocale, enLocale, deLocale],
            locale: calendarLocale,
            initialView: 'multiMonthThreeMonth',
            views: {
                multiMonthThreeMonth: {
                    type: 'multiMonth',
                    duration: { months: numberOfMonths }
                }
            },
            dateIncrement: {
                months: 1
            },
            validRange: function(nowDate:Date) {
                return {
                    start: new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
                };
            },
            events: [],
            selectable: true,
            unselectAuto: false, // We prevent the unselect when click outside of the calendar
            // aspectRatio: 3,
            selectLongPressDelay: 0,
            height: "auto",
            weekends: true,
            select: onDatesSelected,
            unselect: onDatesUnselected,
            dateClick : onDateClick,
            selectAllow : checkSelectAllow,
            dayCellClassNames : dayCellClassNames,
            customButtons: {
                prev: {
                    click: async function() {
                        let calendarApi = (fullCalendar as any).value.getApi();
                        var startDate = FromLocalToUTC(calendarApi.view.activeStart);
                        // We add one week to be sure we are on the right month
                        addDays(startDate, 8);
                        startDate.setMonth(startDate.getMonth() - 1);
                        startDate = startMonth(startDate)
                        var endDate = endMonth(startDate)

                        // We need to check if we have availablities for this month already or not.
                        // We only check if we have availability for the first and last days of the months
                        // as we always retrieve full months
                        var startAvailability = getAvailabilityFromDate(startDate);
                        var endAvailability = getAvailabilityFromDate(endDate);
                        if(!startAvailability || !endAvailability) {
                            // We get availabilities from backend
                            await updateCalendar(props.apartment.code, {
                                startDate: startDate,
                                endDate: endDate
                            })
                        }

                        // We trigger the navigation
                        calendarApi.prev();

                        currentStartDate.value = FromLocalToUTC(calendarApi.view.activeStart);
                        currentEndDate.value = FromLocalToUTC(calendarApi.view.activeEnd);
                    }
                },
                next: {
                    click: async function() {
                        let calendarApi = (fullCalendar as any).value.getApi();
                        var startDate = FromLocalToUTC(calendarApi.view.activeStart);
                        addDays(startDate, 8); // We add 8 days to ensure we are in the next month as active start can be days in previous month.
                        startDate.setMonth(startDate.getMonth() + 3);
                        startDate = startMonth(startDate)
                        var endDate = endMonth(startDate)

                        // We need to check if we have availablities for this month already or not.
                        // We only check if we have availability for the first and last days of the months
                        // as we always retrieve full months
                        var startAvailability = getAvailabilityFromDate(startDate);
                        var endAvailability = getAvailabilityFromDate(endDate);
                        if(!startAvailability || !endAvailability) {
                            // We get availabilities from backend
                            await updateCalendar(props.apartment.code, {
                                startDate: startDate,
                                endDate: endDate
                            })
                        }

                        calendarApi.next();

                        currentStartDate.value = FromLocalToUTC(calendarApi.view.activeStart);
                        currentEndDate.value = FromLocalToUTC(calendarApi.view.activeEnd);
                    }
                }
            }
        })

        /**
         * computedRate
         * This method update the computed price based on user selection
         */
        const computedRate = computed(() => {
            if(selectionForm.startDate && selectionForm.endDate) {
                var price = {
                    amount: 0
                }
                // We should compute the price doing sum
                var selectedEndDate = new Date(selectionForm.endDate);
                removeDays(selectedEndDate,1)
                var lastNight = new Date(selectedEndDate);
                var numberOfNights = 0;
                for(var day = new Date(selectionForm.startDate); day.getTime() <= lastNight.getTime() ; addDays(day, 1) ) {
                    var availability = getAvailabilityFromDate(day);
                    addPrice(price, availability.price, 1);
                    numberOfNights++
                }
                return {
                    price: price,
                    numberOfNights: numberOfNights
                }
            }
            return undefined;
        })

        const visibleAvailabilities = computed(() => {
            var startDate = currentStartDate.value;
            var endDate = currentEndDate.value;
            return periodAvailabilities.value.filter((availability:any) => {
                return new Date(availability.startDate).getTime() <= endDate.getTime() && new Date(availability.endDate).getTime() >= startDate.getTime();
            }).sort((a:any, b:any) => {
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            })
        })

        watch(
            availabilities,
            (val:any, oldVal:any) => {
                if(availabilities.value) {
                    var events = [];
                    var isPreviousDayAvailable = false;
                    for(var monthKey of Object.keys(availabilities.value)) {
                        var monthCalendar = availabilities.value[monthKey];
                        events.push(...monthCalendar.map((availability:any) => {
                            var date = new Date(availability.date);
                            var backgroundColor = availability.price || isPreviousDayAvailable ? "#ccffcc" : "#ccc";
                            isPreviousDayAvailable = availability.price ? true : false;
                            return { 
                                date: date,
                                allDay: true,
                                backgroundColor : backgroundColor,
                                display: "background"
                            }
                        }))
                    }
                    calendarOptions.value.events = events;
                }
                else {
                    calendarOptions.value.events = [];
                }
            },
            { deep: true }
        )

        
        const formatDate = (date:Date) => {
            return formatDay(date, currentLanguageCode, {type: FormatDateType.LONG, isUTC:true});
        }

        const onAddToCartClick = (evt:Event) => {
            evt.preventDefault();

            laddaSubmit!.start();
            app.$hospitality.cart.addRental(props.apartment, selectionForm.startDate, selectionForm.endDate, computedRate.value!.price.amount).then((response:any) => {
                laddaSubmit!.stop();
                if(response.added) {
                    var params:any = {
                        lang : currentLanguageCode,
                    }
                    // We redirect to the cart page
                    return router.push({
                        name : "hospitality-checkout",
                        params : params
                    }).catch((err:any) => {});
                }
                
            });
        }

        // let calendarApi = this.$refs.fullCalendar.getApi()
        // calendarApi.next()

        return {
            availabilityCalendar,
            isCalendarLoading,
            availabilities,
            calendarOptions,
            fullCalendar,
            selectionForm,
            formatDate,
            computedRate,
            onAddToCartClick,
            periodAvailabilities,
            visibleAvailabilities
        }

    }
})      
</script>